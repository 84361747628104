import { render, staticRenderFns } from "./Dolomites.vue?vue&type=template&id=75c57151&scoped=true&"
import script from "./Dolomites.vue?vue&type=script&lang=js&"
export * from "./Dolomites.vue?vue&type=script&lang=js&"
import style0 from "./Dolomites.vue?vue&type=style&index=0&id=75c57151&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75c57151",
  null
  
)

export default component.exports